import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';

@Component({
  selector: 'app-reports-view-modal',
  templateUrl: './reports-view-modal.component.html',
  styleUrls: ['./reports-view-modal.component.scss']
})
export class ReportsViewModalComponent {
  @Input() selectedReport;
  @Input() selectedPatient;
  @Input() isCommonView;
  @Input() reports;
  @Input() patientMetaData;
  @Output() close = new EventEmitter();
  @Output() initPersonalDownload = new EventEmitter();
  @Output() initDownload = new EventEmitter();
  constructor(private user:UserService, private practitionerPipe: PartitionerDetailsPipe, private translatePipe: TranslatePipe, private facilityPipe: FacilityDetailsPipe){

  }


  downloadPersonalReport(ev) {
    this.initPersonalDownload.emit(ev);
  }

  download() {
    this.formDownloadData(this.selectedReport,this.selectedReport['data']);
  }

  private formDownloadData(selectedReport, downloadcontent) {
    console.log("downloadcontent",downloadcontent)
    let practitioner = this.practitionerPipe.transform(selectedReport['practitionerId'], selectedReport['facilityId'], 'resourceName');
    let practitionerName = this.translatePipe.transform(practitioner, 'LOCALE', 'name');
    let facility = this.facilityPipe.transform(selectedReport['facilityId'], 'facilityName');
    let facilityName = this.translatePipe.transform(facility, 'LOCALE', 'name')
    if (selectedReport['format'] == 'FORMFORMAT') {
      // downloadcontent = downloadcontent['topics'];
    }
    let data = {
      content: downloadcontent,
      facilityName: facilityName,
      facilityId:selectedReport['facilityId'],
      reportId:selectedReport['reportId'],
      patientName: this.selectedPatient['personname'],
      uhid: this.selectedPatient['uhid'],
      personId: this.selectedPatient['personId'],
      gender: this.selectedPatient['gender'] == 'M' ? 'Male' : this.selectedPatient.gender == 'F' ? 'Female' : '',
      repDateTime: this.user.getMoment(selectedReport['reportDateTime']).format("DD-MM-YYYY hh:mm a"),
      age: this.user.getMoment().diff(this.user.getMoment(this.selectedPatient['dob']), 'years'),
      reportedBy: selectedReport['authorizedBy'],
      isShare: false,
      filename: "random.pdf",
      reportType: selectedReport['format'],
      recordType: selectedReport['reportType'],
      encounterId: selectedReport['encounterId'],
      specialtyCode: selectedReport['specialtyCode'],
      entityId: selectedReport['entity'],
      doctorName: practitionerName,
      reportName: selectedReport['format'] == 'JSONFORMAT' ? selectedReport['eventGroupDesc'] : selectedReport['eventDesc'],
      reportData: selectedReport
    }
    this.getPDF(data);
  }
  private getPDF(data) {
    this.user.convertToPDF(data).subscribe(data => {
      let link = data['downloadLink'];
      this.user.openLocation(link, '_blank')
    })
  }
  closeModal(){
    this.close.emit();
  }

  choosePrevious() {
    let index = this.reports.findIndex(r => r['id'] == this.selectedReport['id']);
    this.selectedReport = this.reports[index <= 0 ? this.reports.length - 1 : index - 1];
  }
  chooseNext() {
    let index = this.reports.findIndex(r => r['id'] == this.selectedReport['id']);
    this.selectedReport = this.reports[(index >= this.reports.length - 1) ? 0 : index + 1];
  }

}
